
.info-box{
    position: relative;
    background-position: right 16px bottom;
    background-size:  30% auto;
    background-repeat: no-repeat;
    background-image: url(/images/sub/about/contact/cantact-bg.png);
}
.line{
    width: 1px;
    height: 12px;
    background-color: var( --border-color);
}

@media (min-width:576px){
}
@media (min-width:768px){
    .info-box{
    background-position: right 30px bottom;
    background-size:  20% auto;
}
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

